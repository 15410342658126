import * as React from 'react'
import SbEditable from 'storyblok-react'
import { graphql } from 'gatsby'

import useStoryblok from '../lib/storyblok'

import Seo from '../components/shared/seo'
import Layout from '../components/layout/layout'
import DynamicComponent from '../components/dynamicComponent'

const IndexPage = ({ data, location }) => {
  let story = data.storyblokEntry
  story = useStoryblok(story, location)

  const components = story.content.body.map(blok => {
    return (<DynamicComponent blok={blok} key={blok._uid} />)
  })

  return (
    <Layout>
      <Seo lang="de" title={story.name} />
      <SbEditable content={story.content}>
        { components }
      </SbEditable>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    storyblokEntry(full_slug: {eq: "home"}) {
      content
      name
    }
  }
`
